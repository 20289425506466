// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import numeral from "numeral";

@observer
export default class ShoppingCart extends React.Component {

  @observable isLoading = true;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    setTimeout(action(() => {
      this.props.app.showLoading = false;
      this.isLoading = false;
    }), 500);
  }

  @computed get items() {
    return this.props.app.shoppingCart;
  }

  @computed get cost() {
    return this.items.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  @action.bound editItem(item) {
    this.props.app.editingCartItem = item;
    this.props.app.showView(item.link);
  }
  @action.bound removeItem(item) {
    const index = this.props.app.shoppingCart.findIndex((i) => { return i.id == item.id; });
    if(index >= 0) {
      this.props.app.shoppingCart.splice(index, 1);
    }
  }
  @action.bound checkout() {
    this.props.app.showView("/checkout");
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const {
      items,
      cost,
      checkout
     } = this;

    if(items.length == 0) {
      return <div className="view cart">
        <div className="category-title">Shopping Cart</div>
        <div className="empty-category">Your shopping cart is empty! Visit the Galleries or Products page to find items you might be interested in.</div>
      </div>;
    }

    return (
      <div className="view cart">
        <div className="category-title">Shopping Cart</div>
        <div className="cart-list">
          {items.map((item) => {
            return <div className="item" key={"item-" + item.id}>
              <div className="item-image" style={{backgroundImage:"url(" + (item.image || "/assets/images/missing-image.png") + ")"}}/>
              <div className="item-details">
                <div className="item-name" dangerouslySetInnerHTML={{__html: item.label}}></div>
                <div className="item-options" dangerouslySetInnerHTML={{__html: item.options}}></div>
              </div>
              <div className="item-price">{numeral(item.cost).format("$0,0.00")}</div>
              <div className="item-actions">
                <div className="button edit" onClick={() => { this.editItem(item); }}><i className="fas fa-edit"></i> Edit Item</div>
                <div className="button delete" onClick={() => { this.removeItem(item); }}><i className="fas fa-trash"></i> Remove Item</div>
              </div>
            </div>;
          })}
          <div className="purchase-details">
            <div className="subtotal">Subtotal: <span className="price">{numeral(cost).format("$0,0.00")}</span></div>
            <div className="button purchase" onClick={checkout}>Proceed to Check Out</div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}