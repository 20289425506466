// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Galleries extends React.Component {

  @observable galleryList = [];
  @observable isLoading = true;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    setTimeout(action(() => {
      this.props.app.showLoading = false;
      this.isLoading = false;
      this.galleryList = [
        {
          id: "09788096-017d-4158-99a9-f6bc34e2c4c9",
          name: "Colorado - Mountains",
          image: "/assets/images/thumb/WEB06.jpg"
        },
        {
          id: "eb347c0b-cd45-4bb9-be23-e473ad4ac125",
          name: "Colorado - Desert",
          image: "/assets/images/thumb/WEB04.jpg"
        },
        {
          id: "c245bd56-f738-4bbd-8a0a-a09cec9e99e4",
          name: "Utah",
          image: "/assets/images/thumb/WEB09.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f7f",
          name: "Black & White",
          image: "/assets/images/thumb/WEB08.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f71",
          name: "Other Gallery 1",
          image: "/assets/images/thumb/WEB01.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f72",
          name: "Other Gallery 2",
          image: "/assets/images/thumb/WEB02.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb34557f73",
          name: "Other Gallery 3",
          image: "/assets/images/thumb/WEB03.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f74",
          name: "Other Gallery 4",
          image: "/assets/images/thumb/WEB04.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f75",
          name: "Other Gallery 5",
          image: "/assets/images/thumb/WEB05.jpg"
        },
        {
          id: "ea097c9a-fd27-48ff-94ec-9bdb24557f76",
          name: "Other Gallery 6",
          image: "/assets/images/thumb/WEB06.jpg"
        },
      ];
    }), 500);
  }

  @computed get galleries() {
    return this.galleryList;
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const galleries = this.galleries;

    return (
      <div className="view galleries">
        <div className="category-title">Galleries</div>
        <div className="item-list">
          {galleries.map((gallery) => {
            return <div className="item" key={"item-" + gallery.id} onClick={(e) => { showView("/products/photos?gallery=" + gallery.id); }}>
              <img src={gallery.image}/>
              <div className="item-name" dangerouslySetInnerHTML={{__html: gallery.name}}></div>
            </div>;
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}