// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

import Panel from "common/components/panel";
import Button from "common/components/button";

@observer
export default class Header extends React.Component {

  @observable navOpen = false;

  constructor(props) {
    super(props);
  }

  @action.bound logout() {
    confirm("Are you sure you want to logout?", () => {
      this.props.app.logout();
    });
  }

  componentWillMount() { }

  render() {

    const app = this.props.app;
    const {
      currentView,
      showView,
      isLoggedIn,
      loggedInUser,
      shoppingCart
    } = app;

    return (
      <div className="header">
        <div className="hide-tablet">
          <div className="logo" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/"); }}></div>
          <div className="center">
            <a href="/galleries" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/products/category?category=WPECPVFF3EVAH5V66RQSEXJB"); }} className="link">IMAGES</a>
            <a href="/products" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/products"); }} className="link">PRODUCTS</a>
            <a href="/about" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/about"); }} className="link">ABOUT</a>
            <a href="/contact" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/contact"); }} className="link">CONTACT</a>
          </div>
          <div className="right">
            <div className="social">
              <a target="_blank" href="https://www.facebook.com/MtnCloud/"><i className="fab fa-facebook-square"></i></a>
            </div>
            <div className="search">
              <span className="fa-layers fa-fw" onClick={() => { showView("/cart"); }}>
                <i className="fas fa-shopping-cart"></i>
                {shoppingCart.length > 0 && <span className="fa-layers-counter" style={{ background: "#44c9f6" }}>{shoppingCart.length}</span>}
              </span>
              <input className="search" type="text" placeholder="SEARCH" value={app.searchDebounce} onChange={(e) => { app.searchDebounce = e.target.value; }}/>
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>
        <div className="show-tablet">
          <div className="logo" onClick={(e) => { e.stopPropagation(); e.preventDefault(); showView("/"); }}></div>
          <div className="menu-toggle" onClick={() => { this.navOpen = !this.navOpen; }}><i className="fas fa-bars"></i></div>
          <div className={"nav-menu" + (this.navOpen ? " show" : "")}>
            <div onClick={(e) => { this.navOpen = !this.navOpen; }} className="close"><i className="fas fa-times"></i></div>
            <div onClick={(e) => { this.navOpen = false; showView("/"); }} className="link">HOME</div>
            <div onClick={(e) => { this.navOpen = false; showView("/products/category?category=WPECPVFF3EVAH5V66RQSEXJB"); }} className="link">IMAGES</div>
            <div onClick={(e) => { this.navOpen = false; showView("/products"); }} className="link">PRODUCTS</div>
            <div onClick={(e) => { this.navOpen = false; showView("/about"); }} className="link">ABOUT</div>
            <div onClick={(e) => { this.navOpen = false; showView("/contact"); }} className="link">CONTACT</div>
            <div onClick={(e) => { this.navOpen = false; showView("/cart"); }} className="link">SHOPPING CART ({shoppingCart.length} ITEMS)</div>

            <div className="social">
              <a target="_blank" href="https://www.facebook.com/MtnCloud/"><i className="fab fa-facebook-square"></i></a>
            </div>
            <div className="search">
              <input className="search" type="text" placeholder="SEARCH" value={app.searchDebounce} onChange={(e) => { app.searchDebounce = e.target.value; }}/>
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}