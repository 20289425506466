// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Button extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() { }

  render() {

    const { 
      className,
      disabled,
      onClick,
      tooltip,
      style
    } = this.props;

    return (
      <div className={"button " + (disabled ? "disabled " : "") + className} style={style || {}} onClick={(e) => { if (!disabled) { onClick(e); } }}>
        {this.props.children}
        {tooltip && <div className="tooltip" dangerouslySetInnerHTML={{__html: tooltip}}></div>}
      </div>
    );
  }

  componentDidMount() {}
}