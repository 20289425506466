
export default function makeAPICall(url, type, data) {

	return new Promise( (resolve, reject) => {
		if(!url) {
			throw new Error("URL must point to the API server.");
		}
		if(type != "POST" && type != "GET" && type != "PUT" && type != "DELETE") {
			throw new Error("Request type must be GET, POST, PUT, or DELETE.");
		}

    let userData = {};
    try {
      userData = JSON.parse(window.localStorage.getItem("userData") || "{}");
    } catch(e) {
      userData = {};
    }

    const request = {
      request: url,
      method: type,
      data: data,
      authToken: userData.lastAuthToken || ""
    };

    let responseCode = 200;

    const xhr = new XMLHttpRequest();
    xhr.addEventListener("error", (xhr) => {
      let err = new Error("Request failed.");
      try {
        err.request = xhr;
        err.number = xhr.status;
        let message = xhr.getResponseHeader(HDR_ERROR) || xhr.responseText || xhr.statusText;
        if (message) {
          err.message = message;
        }
      }
      catch (e) {
      }

      reject({
        message: url + " (" + err.message + ")",
        status: xhr.status,
        description: "" + type + " " + url + " " + " (" + err.message + ")"
      });
      return err;
    }, xhr);
    xhr.addEventListener("abort", () => {
      reject(new Error("Request aborted."), xhr);
    });
    xhr.addEventListener("timeout", () => {
      reject(new Error("Request timed out."), xhr);
    });
    xhr.addEventListener("load", () => {
      try {
        if (xhr.readyState !== 4) {
          return;
        }
        let isError = false;
        let data = null;
        let contentType = xhr.getResponseHeader("content-type");

        if (contentType && contentType.match(/application\/json/gi)) {
          if (xhr.responseText) {
            data = JSON.parse(xhr.responseText);
          }
          else {
            if (xhr.responseJSON) {
              data = JSON.parse(xhr.responseJSON);
            }
            else {
              data = xhr.response;
            }
          }
        }
        else {
          isError = true;
          data = {
            message: "Request failed and server supplied no error information.",
            description: "Server response in unexpected '" + (contentType || "").toString() + " format.",
            data: xhr.response
          };
        }

        if (data) {
          userData.lastAuthToken = data.authToken;
          window.localStorage.setItem("userData", JSON.stringify(userData));
        }

        if ((xhr.status != 200 && xhr.status != 203) || isError) {
          reject({
            message: url + " (" + xhr.statusText + ")",
            status: xhr.status,
            description: "" + type + " " + url + " " + xhr.status.toString() + " (" + xhr.statusText + ")"
          });
          return;
        }
        else {
          resolve(data.data);
        }
      }
      catch (error) {
        reject(error);
      }
    });


    xhr.open("POST", "/", true, "", "");
    //xhr.open("POST", "https://us-central1-eclosion-staging.cloudfunctions.net/mountaincloud", true, "", "");
    //xhr.open("POST", "https://us-central1-flawless-snow-220302.cloudfunctions.net/mountaincloud", true, "", "");

    xhr.setRequestHeader("Accept", "*/*");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Cache-Control", "no-cache");

    xhr.send(JSON.stringify(request));
	});
}
