// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed, autorun } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Search extends React.Component {

  @observable items = [];
  @observable isLoading = true;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    autorun(() => {
      const search = window.app.search;
      this.items = [];
      if(search && search.length >= 3) {
        this.isLoading = true;
        this.props.app.makeAPICall("search", "GET", {search: search}).then((data) => {
          this.isLoading = false;
          
          this.items = data;
        });
      }
      else {
        this.props.app.showLoading = false;
        this.isLoading = false;
      }
    }, {
      delay: 300
    });
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const items = this.items;

    return (
      <div className="view photos">
        {app.search.length < 3 && <div className="category-title">You must enter at least 3 letters to search for.</div>}
        {app.search.length >= 3 && items.length == 0 && !this.isLoading && <div className="category-title">No results found for <b>{app.search}</b>.</div>}
        <div className="item-list">
          {items.map((item) => {
            return <div className="item" key={"item-" + item.id} onClick={() => { showView("/products/purchase?category=" + item.categoryId + "&item=" + item.id); }}>
              <img src={item.image || "/assets/images/missing-image.png"}/>
              <div className="item-name" dangerouslySetInnerHTML={{__html: item.name}}></div>
            </div>;
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}