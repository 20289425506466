// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Footer extends React.Component {

    constructor(props) {
      super(props);
    }

    componentWillMount() { }

    render() {

      const app = this.props.app;
      const {
        currentView,
        showView,
        isLoggedIn,
        loggedInUser
      } = app;

      return (
        <div className="footer">
          <div className="copyright">FINE ART PHOTOGRAPHY <span style={{fontSize:"0.7em"}}>BY</span> TODD CAUDLE</div>
        </div>
      );
    }

    componentDidMount() {
    }
}