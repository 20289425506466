// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import numeral from "numeral";

@observer
export default class ItemPurchase extends React.Component {

  @observable item = null;
  @observable category = null;
  @observable isLoading = true;
  @observable.ref editingCartItem = null;

  @observable variant = "";

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    const queryVars = this.props.app.queryVars;
    const itemId = queryVars["item"];
    const categoryId = queryVars["category"];

    if(!itemId || !categoryId) {
      if(!this.categoryId) {
        this.props.app.showView("/products");
      }
      else {
        this.props.app.showView("/products/category?category=" + this.categoryId);
      }
    }

    this.props.app.makeAPICall("item", "GET", {item: itemId, category: categoryId, returnCategory: true}).then((data) => {
      this.item = data.item;
      this.category = data.category;
      this.props.app.showLoading = false;
      this.isLoading = false;

      if(!this.item) {
        if(!this.category) {
          this.props.app.showView("/products");
        }
        else {
          this.props.app.showView("/products/category?category=" + this.category.id);
        }
      }

      if(this.props.app.editingCartItem) {
        this.editingCartItem = this.props.app.editingCartItem;
        this.props.app.editingCartItem = null;
        this.variant = this.editingCartItem.variant;
      }

      if(this.item.variations.length == 1) {
        this.variant = this.item.variations[0].id;
      }
      
    });
  }

  @computed get selectedVariant() {
    return this.item && this.item.variations.find((v) => { return v.id == this.variant; });
  }
  @computed get cost() {
    return !this.selectedVariant ? "$--.--" : numeral(this.selectedVariant.price).format("$0,0.00");
  }
  @computed get isImage() {
    return this.category && this.category.id == "WPECPVFF3EVAH5V66RQSEXJB";
  }

  @action.bound addToCart() {
    const {
      category,
      item,
      variant,
      selectedVariant,
      editingCartItem,
      props: {
        app
      }
    } = this;

    if(item && selectedVariant) {
      const cost = selectedVariant.price;

      if(editingCartItem) {
        editingCartItem.variant = variant;
        editingCartItem.label = item.name;
        editingCartItem.options = selectedVariant.name;
        editingCartItem.cost = cost;
      }
      else {
        app.shoppingCart.push({
          id: item.id + variant + (app.shoppingCart.length + 1),
          item: item.id,
          variant: variant,
          label: item.name,
          options: selectedVariant.name,
          cost: cost,
          image: item.image,
          link: "/products/purchase" + window.location.search
        });
      }

      app.showView("/cart");
    }
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const {
      category,
      item,
      variant,
      selectedVariant,
      cost,
      addToCart,
      editingCartItem,
      isImage
    } = this;

    if(!item) {
      return <div></div>;
    }

    return (
      <div className="view purchase">
        <div className="bread-crumbs" key="1">
          <div className="link" onClick={() => { showView("/products"); }}>Products</div>
          <div className="arrow"><i className="fas fa-angle-right"></i></div>
          {category && [<div className="link" key="category-1" onClick={() => { showView("/products/category?category=" + category.id); }}>{category.name}</div>, <div className="arrow" key="arrow-1"><i className="fas fa-angle-right"></i></div>]}
          <div className="link" onClick={() => { showView("/products/purchase?" + (category ? "category=" + category.id + "&" : "") + "item=" + item.id); }}>{item.name}</div>
        </div>
        <div className="product">
          <div className="product-image" style={{backgroundImage: "url(" + (item.image || "/assets/images/missing-image.png") + ")"}}>
            <img src={item.image || "/assets/images/missing-image.png"}/>
          </div>
          <div className="product-details">
            <div className="product-name">{item.name}</div>
            <div className="product-description">{item.description}</div>
            {item.variations.length > 1 && <div className="product-options">
              {!selectedVariant ? [
                <div className="option-title" key="1">{isImage ? "Select a Size and Frame Option" : "Select and Option"}</div>,
                item.variations.map((type) => {
                  return <div className="option" key={type.id} onClick={() => { this.variant = type.id; }}>
                    <div className="option-name">{type.name}</div>
                    <div className="option-cost">{numeral(type.price).format("$0,0.00")}</div>
                    {/*<div className="option-image"></div>
                    <div className="option-description">{type.description}</div>*/}
                  </div>;
                })
              ] : 
                <div className="option-selected" key="2" onClick={() => { this.variant = ""; }}>
                  <div className="option-name">{selectedVariant.name}</div>
                  <div className="option-cost">{numeral(selectedVariant.price).format("$0,0.00")}</div>
                  <div className="option-change"><i className="fas fa-angle-double-down"></i></div>
                </div>
              }
            </div>}
          </div>
        </div>
        <div className="purchase-details">
          <div className="price">{cost}</div>
          <div className={"button add-to-cart " + (!selectedVariant ? "disabled" : "")} onClick={addToCart}>{editingCartItem ? "SAVE CHANGES" : "ADD TO CART"}</div>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}