// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable } from "mobx";
import { observer } from "mobx-react";

import AppModel from "models/appModel";
import Header from "./header";
import Footer from "./footer";
import LoadingOverlay from "common/components/loading";

@observer
export default class App extends React.Component {

  @observable appModel = null;

  constructor(props) {
    super(props);

    window.tempData = {};
    this.appModel = new AppModel(this);
    window.app = this.appModel;
  }

  componentWillMount() { }

  render() {
    const {
      CurrentView,
      NextView
    } = this.appModel;

    return (
      <div>
        <Header app={this.appModel}/>
        <div id="selected-view" className={"selected-view" + (NextView ? " transition" : "")}>
          <CurrentView app={this.appModel}/>
        </div>
        <LoadingOverlay visible={this.appModel.showLoading}/>
        {this.appModel.alert}
      </div>
    );
  }

  componentDidMount() {
    var element = document.getElementById("preloader");
    element.style.opacity = 0;
    setTimeout(() => {
      element.remove();
    }, 500);
  }
}