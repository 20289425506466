// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
export default class LoadingOverlay extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() { }

  render() {

    const visible = this.props.visible;

    return (
      <div className={"loading-overlay" + (visible ? " visible" : "")}>
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-pulse"></i>
        </div>
        <div className="loading-text">Please wait ...</div>
      </div>
    );
  }

  componentDidMount() {}
}