// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed, autorun, toJS } from "mobx";

//import DataModel from "./dataModel";
import Alert from "common/components/alert";
import makeAPICall from "common/utils";

const viewMap = {};
function importAll (r) {
  r.keys().forEach(key => viewMap[key] = r(key));
}
importAll(require.context('../views/', true, /\.js$/));

export default class AppModel {
    
	@observable CurrentView = null;
  @observable NextView = null;
  @observable currentViewData = {
    selectedId: 0,
  };

	@observable showLoading = false;
  @observable alert = null;
  @observable loggedInUser = null;

  @observable shoppingCart = [];
  @observable.ref editingCartItem = null;
  @observable search = "";
  @observable searchDebounce = "";

	constructor() {

		// set native alert
		const nativeAlert = window.alert;
		const nativeConfirm = window.confirm;
		window.alert = (message, callback) => {
			this.alert = <Alert confirm={false} message={message} visible={true} onConfirm={ () => { if(callback) {callback();} this.alert = null;}}/>
		}
		window.confirm = (message, onConfirm, onCancel) => {
			this.alert = <Alert confirm={true} message={message} visible={true} onConfirm={ () => { if(onConfirm) {onConfirm();} this.alert = null;}} onCancel={ () => { if(onCancel) {onCancel();} this.alert = null;}}/>
    }
    
		window.onpopstate = (e) => {
      this.showView(window.location.pathname + window.location.search);
		}
    this.showView(window.location.pathname + window.location.search, true);

    try {
      if(window.localStorage.getItem("cart")) {
        const cart = window.localStorage.getItem("cart");
        this.shoppingCart = JSON.parse(cart);
      }
    }
    catch(e) {
      console.error(e);
      this.shoppingCart = [];
      window.localStorage.setItem("cart", null);
    }
    autorun(() => {
      const cart = this.shoppingCart
      window.localStorage.setItem("cart", JSON.stringify(toJS(cart)));
    });

    let searchTimeout = 0;
    autorun(() => {
      const search = this.searchDebounce;
      if(searchTimeout) { 
        clearTimeout(searchTimeout);
      }
      if(search) {
        searchTimeout = setTimeout(action(() => {
          this.search = search.trim();
          this.showView("/search?search=" + search);
        }), 300);
      }
    }, {
      delay: 300
    });
  }

  get queryVars() {
    let queryVars = {};
    var vars = window.location.search.substring(1).split("&");
    for(var len = vars.length, i = 0, split; i < len; i++){
      if(!vars[i]) continue
      split = vars[i].split("=");
      queryVars[split[0]] = decodeURIComponent(split[1]);
    }
    return queryVars;
  }
  
  @action.bound makeAPICall() {
    this.showLoading = true;
    const promise = makeAPICall(...arguments);
    promise.finally(() => {
      this.showLoading = false;
    });
    return promise;
  }

  @action.bound makeNonBlockingAPICall() {
    return makeAPICall(...arguments);
  }
  
  @action.bound showView(path, immediate = false) {
    path = path.replace("/index.html", "");
    const query = path.indexOf("?") >= 0 ? path.substring(path.indexOf("?")) : "";
    path = query ? path.substring(0, path.indexOf("?")) : path;
    let filePath = path;
    let pathData = {};
    const viewName = "." + filePath + ".js";
    const viewIndexName = "." + (filePath[filePath.length - 1] == "/" ? filePath.substring(0, filePath.length - 1) : filePath) + "/index.js";
    const View = viewMap[viewName] ? viewMap[viewName].default : (viewMap[viewIndexName] ? viewMap[viewIndexName].default : null);
    
    if(!View) {
      console.error("Unable to find the view " + filePath);
      return;
    }

    const queryVars = this.queryVars;
    if(immediate && queryVars.search && this.search != queryVars.search) {
      this.search = queryVars.search;
      this.searchDebounce = queryVars.search;
    }

    /*if(((this.CurrentView && this.CurrentView.name == View.name) || (this.NextView && this.NextView.name == View.name)) && this.currentViewData == pathData) {
      if(window.location.href != window.location.origin + path + query) {
        window.history.pushState({}, View.name, path + query);
      }
      return;
    }*/

    for(let i in pathData) {
      this.currentViewData[i] = pathData[i];
    }
    if(immediate) {
      this.CurrentView = View;
      this.NextView = null;
    }
    else {
      this.NextView = View;
      setTimeout(() => {
        if(window.location.href != window.location.origin + path + query) {
          window.history.pushState({}, View.name, path + query);
        }
        this.CurrentView = this.NextView;
        document.getElementById("selected-view").scrollTop = 0;
        setTimeout(() => {
          this.NextView = null;
        }, 10);
      }, 500);
    }
  }
}