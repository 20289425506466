// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

@observer
export default class SlideShow extends React.Component {

  @observable image = 0;
  @observable transition = false;
  @observable scale = 1;
  static imageInterval = null;

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setScale();
    window.addEventListener('resize', this.setScale);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScale);
  }

  @action.bound setScale() {
    const ratio = document.documentElement.clientWidth / document.documentElement.clientHeight;
    if(ratio <= 1.55 || ratio >= 2.1) {
      this.scale = 1;
    }
    else {
      const factor = 2.1 - ratio;
      this.scale = 1 + (0.6 * factor);
    }
  }

  render() {

    const {
      images,
      position,
      children
    } = this.props;

    const {
      image,
      transition,
      scale
    } = this;

    const width = screen.width;
    const folder = "/assets/images/high/";// + (width <= 640 ? "low" : (width <= 1200 ? "medium" : "high")) + "/";

    return (
      <div className={"slideshow " + position + (transition ? " transition" : "")}>
        <div className="slider">
          <div className="image current" style={{backgroundImage: "url(" + folder + images[(image % images.length)] + ")", transform: "scale(" + scale + ")"}}></div>
          <div className="image next" style={{backgroundImage: "url(" + folder + images[((image + 1) % images.length)] + ")", transform: "scale(" + scale + ")"}}></div>
        </div>
        <div className={"title-area" + (!children || children.length == 0 ? " no-background" : "")}>
          {children}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if(this.constructor.imageInterval) {
      clearInterval(this.constructor.imageInterval);
    }
    this.constructor.imageInterval = setInterval(action(() => {
      this.transition = true;
      setTimeout(action(() => {
        ++this.image;
        this.transition = false;
      }), 2000);
    }), 7000);
  }
  
  componentWillUnmount() {
    if(this.constructor.imageInterval) {
      clearInterval(this.constructor.imageInterval);
    }
  }
}