// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Panel extends React.Component {

  @observable expanded = false;

  constructor(props) {
    super(props);
  }

  @action.bound toggle(e) {
    const { 
      expandable,
      onClick,
    } = this.props;

    if(onClick) {
      onClick(e);
    }
    if (expandable) {
      this.expanded = !this.expanded;
    }
  }

  componentWillMount() {
    this.expanded = !!this.props.startExpanded;
  }

  render() {

    const { 
      title,
      className,
      expandable,
      onClick,
      key,
      description,
      children
    } = this.props;

    const expanded = typeof this.props.expanded === "undefined" ? this.expanded : this.props.expanded;

    return (
      <div className={"panel " + (onClick || expandable ? ("expandable " + (expanded ? "open " : "closed ")) : "") + css}>
        <div className="panel-title" onClick={this.toggle}>{title}</div>
        {(onClick || expandable) && <div className="panel-expand" onClick={this.toggle}><i className="fal fa-angle-double-right"></i></div>}
        <div className="panel-description">{description}</div>
        <div className="panel-content">{children}</div>
      </div>
    );
  }

  componentDidMount() {}
}