
__webpack_nonce__ = 'EDNnf03nceIOfn39fn3e9h3sdfa';


import React from "react";
import ReactDOM from 'react-dom';

import App from 'views/main.js'

ReactDOM.render(
  <App/>,
  document.getElementById("application")
);