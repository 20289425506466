// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed, autorun } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Contact extends React.Component {

  @observable name = "";
  @observable email = "";
  @observable message = "";
  
  @observable nameBlurred = false;
  @observable emailBlurred = false;
  @observable messageBlurred = false;

  @observable success = false;

  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  @computed get nameError() {
    return this.nameBlurred && (this.name.length <= 0 ? "* Please enter your name" : (this.name.length > 64 ? "* Name must be less than 64 characters long" : false));
  }
  @computed get emailError() {
    return this.emailBlurred && (this.email.length <= 0 ? "* Please enter your email" : (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.email) ? "* This email address is not valid" : false));
  }
  @computed get messageError() {
    return this.messageBlurred && (this.message.length <= 0 ? "* Please enter your message" : (this.message.length > 64 ? "* Message must be less than 4096 characters long" : false));
  }

  @action.bound submit() {
    if((!this.name || !this.email || !this.message || this.nameError || this.emailError || this.messageError)) {
      return;
    }

    this.props.app.makeAPICall("sendEmail", "GET", {name: this.name, message: this.message, email: this.email}).then((data) => {
      this.success = true;
    });
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const {
      nameError,
      emailError,
      messageError,
      success,
      submit
    } = this;

    if(success) {
      return (
        <div className="view-content flexed column content-center items-center">
          <div className="account-success fas fa-check-circle"></div>
          <div className="account-success-title">
            <span>Message sent successfully.</span>
          </div>
          <div className="account-success-text">
            Thank you for your message to MountainCloud Gallery.<br/>
            We will reply as soon as we are able.
          </div>
        </div>
      );
    }
    return (
      <div className="view contact">
        <div className="category-title">Contact Us</div>
        <div className="contact-form">
          <div className={"input-group" + (nameError ? " error" : "")}>
            <input type="text" placeholder="Name*" value={this.name} onChange={(e) => { this.name = e.target.value; }} onBlur={() => { this.nameBlurred = true; }}/>
            <div className="error">{nameError}</div>
          </div>
          <div className={"input-group" + (emailError ? " error" : "")}>
            <input type="email" placeholder="Email*" value={this.email} onChange={(e) => { this.email = e.target.value; }} onBlur={() => { this.emailBlurred = true; }}/>
            <div className="error">{emailError}</div>
          </div>
          <div className={"input-group" + (messageError ? " error" : "")}>
            <textarea placeholder="Message*" value={this.message} onChange={(e) => { this.message = e.target.value; }} onBlur={() => { this.messageBlurred = true; }}></textarea>
            <div className="error">{messageError}</div>
          </div>
          <div className="button-group">
            <div className={"button" + ((!this.name || !this.email || !this.message || nameError || emailError || messageError) ? " disabled" : "")} onClick={submit}>Submit</div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}