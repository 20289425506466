// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Products extends React.Component {

  @observable products = [];
  @observable isLoading = true;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    this.props.app.makeAPICall("categories", "GET", {}).then((products) => {
      this.props.app.showLoading = false;
      this.isLoading = false;
      
      this.products = products.filter((p) => {
        return p.name != "Images" && p.name != "Shipping";
      });
    });
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const products = this.products;

    return (
      <div className="view products">
        <div className="item-list">
          {products.map((product) => {
            return <div className="item" key={"item-" + product.id} onClick={(e) => { showView("/products/category?category=" + product.id); }}>
              <img src={product.image || "/assets/images/missing-image.png"}/>
              <div className="item-name" dangerouslySetInnerHTML={{__html: product.name}}></div>
            </div>;
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}