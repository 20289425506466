// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed, autorun } from "mobx";
import { observer } from "mobx-react";
import numeral from "numeral";

import CardValidator from "card-validator";

@observer
export default class Checkout extends React.Component {

  @observable isLoading = true;

  @observable inputs = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",

    card: true,
    name: "",
    month: true,
    cvc: true,
    cardZip: true
  }

  @observable blurred = {
    firstName: false,
    lastName: false,
    email: false,
    country: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    zip: false,

    card: false,
    name: false,
    month: false,
    cvc: false,
    cardZip: false,
  }

  @observable loadingShipping = false;
  @observable loadingTax = false;
  @observable shippingRequiresContact = false;
  @observable shippingCost = -1;
  @observable tax = -1;
  @observable lastZip = "";
  @observable cardError = false;
  @observable success = false;

  locationId = "";
  applicationId = "";
  paymentForm = null;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentDidMount() {
    this.props.app.makeAPICall("location", "GET", {}).then(action((data) => {
      this.locationId = data.location;
      this.applicationId = data.application;
      this.createPaymentForm();
    }));

    autorun(() => {
      const zip = this.inputs.zip;
      const errors = this.errors;
      if (zip && !errors.zip && zip != this.lastZip) {
        this.lastZip = zip;
        this.getShipping();
      }
    }, {
      delay: 300
    });
  }

  createPaymentForm() {
    const self = this;
    this.paymentForm = new SqPaymentForm({
      applicationId: this.applicationId,
      locationId: this.locationId,
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [{
        backgroundColor: "transparent",
        lineHeight: "30px",
        padding: "6px 12px",
        fontSize: "18px",
        color: "#555",
      }],
      applePay: false,
      masterpass: false,
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: '• • • •  • • • •  • • • •  • • • •'
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVC'
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY'
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Zip Code'
      },
      callbacks: {
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
            console.log("Encountered errors:");
            errors.forEach((error) => {
              console.log('  ' + error.message);
              if(error.message.indexOf("card number") >= 0) { self.blurred.card = true; self.inputs.card = false; }
              if(error.message.indexOf("CVV") >= 0) { self.blurred.cvc = true; self.inputs.cvc = false; }
              if(error.message.indexOf("date") >= 0) { self.blurred.month = true; self.inputs.month = false; }
              if(error.message.indexOf("postal") >= 0) { self.blurred.cardZip = true; self.inputs.cardZip = false; }
            });
            self.checkingOut = false;
            self.props.app.showLoading = false;
            return;
          }
          console.log(nonce);
          
          const items = self.props.app.shoppingCart.map((item) => {
            return item.variant;
          });
          self.props.app.makeAPICall("checkout", "GET", { items: items, inputs: self.inputs, cardData: cardData, nonce: nonce }).then(action((data) => {
            if (data && data.success) {
              self.success = data.code;
              self.props.app.shoppingCart = [];
            }
            else {
              self.cardError = true;
            }
            self.checkingOut = false;
          })).catch((e) => { 
            self.checkingOut = false;
            self.cardError = true;
          });
        },
        unsupportedBrowserDetected: () => {
          debugger;
        },
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'focusClassRemoved':
              if(inputEvent.field == "cardNumber") {
                self.blurred.card = true;
                self.cardError = false;
              }
              else if(inputEvent.field == "expirationDate") {
                self.blurred.month = true;
              }
              if(inputEvent.field == "cvv") {
                self.blurred.cvc = true;
              }
              if(inputEvent.field == "postalCode") {
                self.blurred.cardZip = true;
              }
              break;
            case 'errorClassAdded':
              if(inputEvent.field == "cardNumber") {
                self.inputs.card = false;
              }
              else if(inputEvent.field == "expirationDate") {
                self.inputs.month = false;
              }
              if(inputEvent.field == "cvv") {
                self.inputs.cvc = false;
              }
              if(inputEvent.field == "postalCode") {
                self.inputs.cardZip = false;
              }
              break;
            case 'errorClassRemoved':
              if(inputEvent.field == "cardNumber") {
                self.inputs.card = true;
              }
              else if(inputEvent.field == "expirationDate") {
                self.inputs.month = true;
              }
              if(inputEvent.field == "cvv") {
                self.inputs.cvc = true;
              }
              if(inputEvent.field == "postalCode") {
                self.inputs.cardZip = true;
              }
              break;
            case 'cardBrandChanged':
              /* HANDLE AS DESIRED */
              break;
            case 'postalCodeChanged':
              /* HANDLE AS DESIRED */
              break;
          }
        },
      }
    });
    this.paymentForm.build();
  }

  @computed get items() {
    return this.props.app.shoppingCart;
  }

  @computed get cost() {
    return this.items.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  @computed get errors() {
    const {
      inputs
    } = this;

    let errors = {};

    if (inputs.firstName.length < 1 || inputs.firstName.length > 64) {
      errors.firstName = "Enter your first name.";
    }
    if (inputs.lastName.length < 1 || inputs.lastName.length > 64) {
      errors.lastName = "Enter your last name.";
    }
    if (inputs.email.length < 1 || inputs.email.length > 128 || !/(.+)@(.+){2,}\.(.+){2,}/.test(inputs.email)) {
      errors.email = "Enter a valid email address.";
    }
    if (inputs.address1.length < 1 || inputs.address1.length > 128) {
      errors.address = "Enter your address.";
    }
    if (inputs.address2.length > 128) {
      errors.address2 = "Enter your address.";
    }
    if (inputs.city.length < 1 || inputs.city.length > 128) {
      errors.city = "Enter your city.";
    }
    if (inputs.state.length < 1 || inputs.state.length > 128) {
      errors.state = "Enter your state.";
    }
    if (inputs.zip.length < 1 || inputs.zip.length > 10) {
      errors.zip = "Enter your zip code.";
    }
    else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(inputs.zip)) {
      errors.zip = "Zip code is not valid.";
    }

    if (inputs.name.length < 1 || inputs.name.length > 64) {
      errors.name = "Enter the name on the card.";
    }
    if (!inputs.card) {
      errors.card = "Card number is not valid.";
    }
    else if (this.cardError) {
      errors.card = "There was a problem processing your payment.";
    }
    if (!inputs.month) {
      errors.month = "Expiration date is not valid.";
    }
    if (!inputs.cvc) {
      errors.cvc = "CVC is not valid.";
    }
    if (!inputs.cardZip) {
      errors.cardZip = "Billing zip code is not valid.";
    }

    return errors;
  }

  @action.bound getShipping() {
    const items = this.props.app.shoppingCart.map((item) => {
      return item.variant;
    });

    this.loadingShipping = true;
    this.shippingCost = 0;
    this.taxes = 0;
    this.props.app.makeNonBlockingAPICall("shipping", "GET", { items: items, zip: this.inputs.zip }).then(action((data) => {
      this.shippingRequiresContact = data.cost == 0 || data.requireContact;
      console.log(this.shippingRequiresContact);
      this.shippingCost = data.cost;
      if (data.taxes) {
        this.taxes = this.cost * 0.029;
      }
      else {
        this.taxes = 0;
      }
      this.loadingShipping = false;
    })).catch((e) => { alert(e); });
  }

  checkingOut = false;
  @action.bound checkout() {
    if(this.checkingOut || Object.keys(this.errors).length > 0) { return; }
    this.checkingOut = true;

    this.isLoading = true;
    this.props.app.showLoading = true;
    this.paymentForm.requestCardNonce();
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const {
      items,
      cost,
      checkout,
      inputs,
      errors,
      blurred
    } = this;

    if (this.success) {
      return (
        <div className="view-content flexed column content-center items-center">
          <div className="account-success fas fa-check-circle"></div>
          <div className="account-success-title">
            <span>Purchase successful.</span>
          </div>
          <div className="account-success-text">
            Thank you for your purchase from MountainCloud Gallery.<br /><br/>
            Your confirmation code is <b>{this.success}</b>.<br/><br/>Copy this code and send it with any support requests or questions you might have about your order.
          </div>
        </div>
      );
    }
    if (items.length == 0) {
      return <div className="view cart">
        <div className="category-title">Checkout</div>
        <div className="empty-category">Your shopping cart is empty! Visit the Galleries or Products page to find items you might be interested in.</div>
      </div>;
    }

    return (
      <div className="view checkout">
        <div className="category-title">Checkout</div>
        <form noValidate className="checkout-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); checkout(); }}>
          <div className="input-container">
            <div className="input-group-title">Shipping Information</div>
            <div className={"input-group" + (errors.firstName && blurred.firstName ? " error" : "")}>
              <label>First Name</label>
              <input type="text" placeholder="John" value={inputs.firstName} onChange={(e) => { this.inputs.firstName = e.target.value; }} onBlur={() => { this.blurred.firstName = true; }} />
              <div className="error">{errors.firstName}</div>
            </div>
            <div className={"input-group" + (errors.lastName && blurred.lastName ? " error" : "")}>
              <label>Last Name</label>
              <input type="text" placeholder="Smith" value={inputs.lastName} onChange={(e) => { this.inputs.lastName = e.target.value; }} onBlur={() => { this.blurred.lastName = true; }} />
              <div className="error">{errors.lastName}</div>
            </div>
            <div className={"input-group" + (errors.email && blurred.email ? " error" : "")}>
              <label>Email</label>
              <input type="email" placeholder="john.smith@example.com" value={inputs.email} onChange={(e) => { this.inputs.email = e.target.value; }} onBlur={() => { this.blurred.email = true; }} />
              <div className="error">{errors.email}</div>
            </div>
            <div className={"input-group" + (errors.address1 && blurred.address1 ? " error" : "")}>
              <label>Address</label>
              <input type="text" placeholder="123 Street Name" value={inputs.address1} onChange={(e) => { this.inputs.address1 = e.target.value; }} onBlur={() => { this.blurred.address1 = true; }} />
              <div className="error">{errors.address1}</div>
            </div>
            <div className={"input-group" + (errors.address2 && blurred.address2 ? " error" : "")}>
              <label>Apt. Number</label>
              <input type="text" placeholder="" value={inputs.address2} onChange={(e) => { this.inputs.address2 = e.target.value; }} onBlur={() => { this.blurred.address2 = true; }} />
              <div className="error">{errors.address2}</div>
            </div>
            <div className={"input-group" + (errors.city && blurred.city ? " error" : "")}>
              <label>City</label>
              <input type="text" placeholder="City" value={inputs.city} onChange={(e) => { this.inputs.city = e.target.value; }} onBlur={() => { this.blurred.city = true; }} />
              <div className="error">{errors.city}</div>
            </div>
            <div className={"input-group" + (errors.state && blurred.state ? " error" : "")}>
              <label>State</label>
              <input type="text" placeholder="State" value={inputs.state} onChange={(e) => { this.inputs.state = e.target.value; }} onBlur={() => { this.blurred.state = true; }} />
              <div className="error">{errors.state}</div>
            </div>
            <div className={"input-group" + (errors.zip && blurred.zip ? " error" : "")}>
              <label>Zip Code</label>
              <input type="text" placeholder="12345" value={inputs.zip} onChange={(e) => { this.inputs.zip = e.target.value; }} onBlur={() => { this.blurred.zip = true; }} />
              <div className="error">{errors.zip}</div>
            </div>
            <div className="input-group-title" style={{ marginTop: "30px" }}>Payment Information</div>
            <div className={"input-group" + (errors.name && blurred.name ? " error" : "")}>
              <label>Name on Card</label>
              <input type="text" placeholder="John Smith" value={inputs.name} onChange={(e) => { this.inputs.name = e.target.value; }} onBlur={() => { this.blurred.name = true; }} />
              <div className="error">{errors.name}</div>
            </div>
            <div className={"input-group" + (errors.card && blurred.card ? " error" : "")}>
              <label>Card Number</label>
              <div id="sq-card-number"></div>
              <div className="error">{errors.card}</div>
            </div>
            <div className={"input-group" + ((errors.month && blurred.month) || (errors.cvc && blurred.cvc) || (errors.cardZip && blurred.cardZip) ? " error" : "")} style={{ marginBottom: "50px" }}>
              <label>Card Details</label>
              <div className="combined-input">
                <div id="sq-expiration-date"></div>
                <div id="sq-cvv"></div>
                <div id="sq-postal-code"></div>
              </div>
              <div className="error">{errors.month || errors.cvc || errors.cardZip}</div>
            </div>
          </div>
          <div className="cart-list">
            {items.map((item) => {
              return <div className="item" key={"item-" + item.id}>
                <div className="item-details">
                  <div className="item-name" dangerouslySetInnerHTML={{ __html: item.label }}></div>
                  <div className="item-options" dangerouslySetInnerHTML={{ __html: item.options }}></div>
                </div>
                <div className="item-price">{numeral(item.cost).format("$0,0.00")}</div>
              </div>;
            })}
            <div className="purchase-details">
              <div className="subtotal">Subtotal: <span className="price">{numeral(cost).format("$0,0.00")}</span></div>
              {this.taxes > 0 && <div className="subtotal shipping">
                <div>Taxes:</div>
                <div className="price">{numeral(this.taxes).format("$0,0.00")}</div>
              </div>}
              <div className="subtotal shipping">
                <div>Shipping:</div>
                {this.loadingShipping ? <div><i className="fas fa-spinner fa-spin"></i></div> : (
                  this.shippingCost < 0 || this.shippingRequiresContact ? <div className="price">$--.--</div> : <div className="price">{numeral(this.shippingCost).format("$0,0.00")}</div>
                )}
              </div>
              {this.shippingRequiresContact && <div className="subtotal shipping warning">
                <div><i className="fas fa-exclamation-circle"></i> One or more of the products you are purchasing requires special shipping considerations. Your shipping fees will be evaluated after purchase. Contact <a href="mailto:info@mountaincloudgallery.com">info@mountaincloudgallery.com</a> for more information.</div>
              </div>}
              <div className="subtotal total">Total: <span className="price">{
                this.shippingCost < 0 || this.loadingShipping ? "$--.--" : numeral(cost + this.shippingCost + this.taxes).format("$0,0.00")
              }</span></div>
              <button id="sq-creditcard" className={"button purchase" + ((this.shippingCost < 0 || this.loadingShipping || Object.keys(errors).length > 0) ? " disabled" : "")} onClick={checkout}>Complete Purchase</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}