// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

import Panel from "common/components/panel";
import Button from "common/components/button";
import SlideShow from "../components/slideshow";
import Footer from "./footer";

@observer
export default class Home extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() { }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const images = [
      "WEB06.jpg",
      "WEB09.jpg",
      "WEB10.jpg",
      "WEB01.jpg",
      "WEB02.jpg",
      "WEB03.jpg",
      "WEB04.jpg",
      "WEB11.jpg",
      "WEB05.jpg",
      "WEB07.jpg",
      "WEB08.jpg",
    ];

    return (
      <div className="view home">
        <SlideShow images={images} position="left">
        </SlideShow>
        <div className="modal-wrapper">
          <div className="center-modal">
            <div style={{fontSize: "3em"}}>Gallery Grand Opening</div>
            <div style={{fontSize: "1.8em"}}>November 16th 4-8 PM<br/>Colorado Mills Mall, Lakewood, CO</div>
            <div style={{fontSize: "1.1em"}}>(across from Dick's Sporting Goods)</div>
          </div>
        </div>
        <Footer app={app}/>
      </div>
    );
  }

  componentDidMount() {
  }
}