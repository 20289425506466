// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";

@observer
export default class ProductList extends React.Component {

  @observable items = [];
  @observable category = {};
  @observable isLoading = true;

  constructor(props) {
    super(props);

    this.props.app.showLoading = true;
    this.isLoading = true;
  }

  componentWillMount() {

    const queryVars = this.props.app.queryVars;
    const categoryId = queryVars["category"];

    if(!categoryId) {
      this.props.app.showView("/products");
      return;
    }

    this.props.app.makeAPICall("categoryItems", "GET", {category: categoryId}).then((data) => {
      this.props.app.showLoading = false;
      this.isLoading = false;
      
      this.category = data.category;
      this.items = data.items;
    });
  }

  @computed get isImage() {
    return this.category && this.category.id == "WPECPVFF3EVAH5V66RQSEXJB";
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const category = this.category;
    const items = this.items;

    return (
      <div className={"view" + (!this.isImage ? " books" : " photos")}>
        {category && [<div className="bread-crumbs" key="1">
          <div className="link" onClick={() => { showView("/products"); }}>Products</div>
          <div className="arrow"><i className="fas fa-angle-right"></i></div>
          <div className="link" onClick={() => { showView("/products/category?category=" + category.id); }}>{category.name}</div>
        </div>,
        <div className="category-title has-crumbs" key="2">{category.name}</div>]
        }
        <div className={"item-list" + (category ? " has-title" : "")}>
          {items.map((item) => {
            return <div className="item" key={"item-" + item.id} onClick={() => { showView("/products/purchase?" + (category ? "category=" + category.id + "&" : "") + "item=" + item.id); }}>
              <img src={item.image || "/assets/images/missing-image.png"}/>
              <div className="item-name" dangerouslySetInnerHTML={{__html: item.name}}></div>
            </div>;
          })}
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}