// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action } from "mobx";
import { observer } from "mobx-react";

@observer
export default class Alert extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() { }

  render() {

    const {
      visible,
      confirm,
      title,
      message,
      onCancel,
      onConfirm
    } = this.props;

    return (
      <div className={"loading-overlay" + (visible ? " visible" : "")}>
        <div className="alert panel grey">
          <div className="panel-title">{title ? title : (confirm ? "Are you sure?" : "Alert!")}</div>
          <div className="panel-content" dangerouslySetInnerHTML={{ __html: message }}></div>
          {confirm ? (
            <div className="panel-buttons">
              <div className="button red" onClick={onCancel}>Cancel</div>
              <div className="button blue" onClick={onConfirm}>Confirm</div>
            </div>
          ) : (
              <div className="panel-buttons">
                <div className="button orange" onClick={onConfirm}>OK</div>
              </div>
            )}
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}