// Import React and React DOM
import * as React from "react";
import ReactDOM from 'react-dom';
import { observable, action, computed, autorun } from "mobx";
import { observer } from "mobx-react";

@observer
export default class About extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  render() {

    const app = this.props.app;
    const showView = app.showView;

    const items = this.items;

    return (
      <div className="view about">
        <div className="about-image">
          <img src="/assets/images/020318-UT-GSENM-Devils-Garden-selfie-H0414.jpg"/>
        </div>
        <div className="about-content">
          <p>Welcome to MountainCloud Gallery! The images in this gallery are the culmination of more than three decades of travel throughout Colorado and the West. Award-winning photographer Todd Caudle has explored countless miles of trails and backroads, primarily in his beloved home state of Colorado, seeking landscapes as they are experiencing their most fleeting moments. Rarely content to photograph a static landscape, he is always in pursuit of those dynamic events as weather ebbs and flows, as nighttime turns to day and back again, and as seasons change. His propensity for capturing dramatic climatic events led one close friend and fellow photographer to dub him Cloudman, and the nickname stuck. In fact, it makes up the “Cloud” portion of the name of the gallery.</p>
          <p>Todd began his photo career in a very different venue. From 1979 to 1994 he chased the Colorado concert scene, first by camping out at ticket outlets to secure the best seats and then sneaking his camera and lens into the arena, and later photographing freelance for a wide variety of local, national and international publications. In the process, he amassed a one-of-a-kind collection of images — most of which have never been seen — a virtual who’s who of big-name entertainers, from Michael Jackson to Metallica, and every genre in-between. He photographed local assignments for Rolling Stone magazine, spent two years as the house photographer for Denver’s historic Paramount Theater, and supplied all the photographs for the Moody Blues’ legendary A <i>Night at Red Rocks</i> CD and video release.</p>
          <p>It was when Todd noticed he was spending more time ogling the sunsets over Red Rocks than the stars on stage that he decided to change directions and pursue his love of Colorado’s natural beauty with his camera full-time. From rock stars to rocks, if you will. In 1994 he left the barricades and the bouncers and the rude road managers behind to chase the light — and the clouds! — across the landscapes he loves so much. In 1992 he started Skyline Press and began publishing calendars, books and note cards featuring the beauty of Colorado. His 2005 book, <i>14,000 Feet – A Celebration of Colorado’s Highest Mountains</i>, won the Colorado Book Award for Best Pictorial Book. He has since begun publishing a Utah scenic calendar to augment his popular Colorado titles.</p>
          <p>Every photograph has a story behind it, Todd likes to say, and if there’s one thing he likes to do, it’s tell a good tale. If you see an image that intrigues you, send him an email and ask him how it came to be.</p>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }
}